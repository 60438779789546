<template>
  <div class="courseInfoWrap">
    <header>
        <el-row class='hedaer-Bg'>
            <el-row>
            <el-col :span="12" class='header-log-Name'>
                    <div class="grid-content header-left-content">
                        <div class='logo-wrap'><img src='../../assets/image/homeImg/logo.png'/></div>
                        <div class='header-left-name' @click="$router.push('/home')">在线培训平台</div>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="grid-content header-right-content">
                        <div @click='pageJumpLogin' v-if="userName==''">登录</div>
                        <div v-else class='userName-wrap'>
                                <el-dropdown placement='bottom'>
                                    <span class="el-dropdown-link">
                                        学员{{userName}}<i class="el-icon-arrow-down el-icon--right"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item @click.native="$router.push('/accountSafe')">账号安全</el-dropdown-item>
                                        <el-dropdown-item @click.native="$router.push('/login')">退出登录</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                        </div>
                </el-col>
            </el-row>
            <navTab></navTab>
            <el-row class='header-courseTitle'>
                <el-col>
                    <div class="title-content">
                        <div>{{courseTitle}}</div>
                    </div>
                </el-col>
                <el-col>
                    <div class="courseNum-content">
                        <div>时间：<span>{{studyDateDesc}}</span></div>
                    </div>
                </el-col>
            </el-row>
        </el-row>
    </header>
    <div class='course-content'>
<!--        <el-row class='handle-content' type='flex'>-->
<!--            <el-col  class='handle-content-col'>-->
<!--                <div class="handle-content-item handle-content-1" @click='setSignin'>-->
<!--                     <img v-show="this.isSignin=='N'" src="../../assets/image/courseInfo/sign.png" alt="">-->
<!--                     <div  v-show="this.isSignin=='N'" >报到</div>-->
<!--                     <img v-show="this.isSignin=='Y'" src="../../assets/image/courseInfo/okSign.png" alt="">-->
<!--                    <div v-show="this.isSignin=='Y'">已报到</div>-->
<!--                </div>-->
<!--            </el-col>-->
<!--            <el-col  class='handle-content-col'>-->
<!--                <div class="handle-content-item handle-content-2" @click='jumpHomeWorkPage()'>-->
<!--                    <img src="../../assets/image/courseInfo/task.png" alt=""><div>作业提交</div>-->
<!--                </div>-->
<!--            </el-col>-->
<!--            <el-col  class='handle-content-col' v-show="teachingStatus=='END'">-->
<!--                <div class="handle-content-item handle-content-2" @click='jumpStudyHistoryPage()'>-->
<!--                    <img src="../../assets/image/courseInfo/studyHistory.png" alt=""><div>观看详情</div>-->
<!--                </div>-->
<!--            </el-col>-->
<!--        </el-row>-->
        <div class='course-content-cover'>
            <div class="course-content-cover-left">
                <div class='course-content-title' v-show='!courseCover_url'>{{courseTitle}}</div>
                <div class='course-content-title hasCover_url' v-show='courseCover_url'>
                    <img :src="courseCover_url" alt="">
                </div>
            </div>
            <div class="course-content-cover-right">
<!--                <div>主讲人：<span>{{courseTeacher}}</span></div>-->
<!--                <div>地点：<span>{{courseAddr}}</span></div>-->
                <div v-if="courseType!=''&&courseType=='VOD'" class="notLiveJoinBox">
                    <div class="progess">进度：<el-progress :percentage="processRateTall"></el-progress></div>
<!--                    <div style='curse:point' class="listenClassBtn" @click='listenNOTLIVEBtn(liveChapterList[0].liveChapterList[0].id)'>立即听课</div>-->
                </div>
            </div>
        </div>
        <div class='course-content-info'>
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="课程介绍" name="first">
                    <el-row class='course-content-introduce'>
                        <el-col>
                            <div class='teacher-info introduce-item introduce-title'>课程班主任</div>
                            <div class='teacher-name introduce-item' v-html='courseHeaderTeacher'></div>
                            <!-- <div class='teacher-phone introduce-item'>联系方式：{{courseHeaderLink}}</div> -->
                        </el-col>
                        <el-col>
                            <div class='course-info introduce-item introduce-title'>课程详情</div>
                            <div class='course-img introduce-item' v-html='courseIntro'></div>
                        </el-col>
                    </el-row>
                </el-tab-pane>
                <el-tab-pane label="课程目录" name="second">
                     <div>
                        <div class='course-intro' v-for="(item_chil,index_chil) in liveChapterList" :key='index_chil'>
                            <el-row type='flex'>
                                <el-col  :span="12">
                                    <div class='course-intro-left'>
<!--                                                <div class='course-intro-left-date'>{{item_chil.beginTime}}~{{item_chil.endTime}}</div>-->
                                        <div class='course-intro-left-name'>{{item_chil.chapterTitle}}</div>
                                        <div class='course-intro-left-teacher'>
                                             <div>主讲:{{item_chil.chapterTeacher}}</div>
                                        </div>
                                    </div>
                                </el-col>
                                <el-col  :span="10">
                                  <div class="notLiveJoinBox">
                                  <div class="progess"><div style="margin: 25px 0;font-size: 16px">进度：</div><el-progress style="max-width: 350px" :percentage="item_chil.processRate"></el-progress></div>
                                  </div>
                                </el-col>
                                <el-col  :span="7">
                                    <div class='course-intro-center'>
                                        <div class='course-intro-center-date' v-show="item_chil.isNeedSignin=='Y'">签到：{{item_chil.isSignin=='N' ? '未签到' :item_chil.signinTime}}</div>
                                        <div class='course-intro-center-name' v-show="item_chil.isNeedSignin=='Y'">签退：{{item_chil.isSignout=='N' ? '未签退':item_chil.signoutTime}}</div>
                                    </div>
                                </el-col>
                                <el-col  :span="5" class='handle-wrap'>
                                    <div v-if="courseType=='OFFLINE'">
                                        <div class='play-wrap' v-if="item_chil.isNeedSignin == 'N' && item_chil.isNeedSignout == 'N'"><img src='../../assets/image/courseInfo/disabledPlay.png' /><div class='noplay'>播放</div></div>
                                        <div class='play-wrap' v-else @click="goOffLinePage(item_chil)"><img src='../../assets/image/courseInfo/play.png' /><div class='play'>播放</div></div>
                                    </div>
                                    <div v-else-if="courseType=='VOD'">
<!--                                                <div class='play-wrap' v-if='item_chil.vodId' @click="goPlayBackPage(item_chil.id)"><img src='../../assets/image/courseInfo/playback.png' /><div class='playback'>回放</div></div>-->
                                       <div v-if='item_chil.vodId'>
                                         <div class='play-wrap' v-show='item_chil.processRate< 10000' @click="goPlayBackPage(item_chil.id)"><img src='../../assets/image/courseInfo/play.png' /><div class='play'>播放</div></div>
<!--                                         <div class='play-wrap' v-show='item_chil.processRate >= 100'><img src='../../assets/image/courseInfo/play.png' /><div class='play' style="color: #808080;margin-top: 5px">播放</div></div>-->
                                       </div>
                                        <div class='play-wrap' v-else @click="goLivePage(item_chil)"><img src='../../assets/image/courseInfo/play.png' /><div class='play'>播放</div></div>
                                    </div>
                                    <div v-else>
                                        <div class='play-wrap' v-if='item_chil.vodId' @click="goPlayBackPage(item_chil.vodId)"><img src='../../assets/image/courseInfo/playback.png' /><div class='playback'>回放</div></div>
                                    </div>
                                    <div class='download-wrap' v-show='item_chil.chapterAttachUrl' @click='downloadChapterAttach(item_chil.chapterAttachUrl)'><img src='../../assets/image/courseInfo/download.png' /><div class='playback'>下载</div></div>
                                    <div class='download-wrap' v-show='!item_chil.chapterAttachUrl' @click='downloadChapterAttach(item_chil.chapterAttachUrl)'><img src='../../assets/image/courseInfo/disabledDownload.png' /><div class='noplay'>下载</div></div>
                                </el-col>
                            </el-row>
                            <el-divider></el-divider>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
    <footer-com></footer-com>
  </div>
</template>

<script>
import footerCom from '@/components/footer.vue'
import navTab from '@/components/navTab.vue'
import { mapMutations } from 'vuex'
export default {
  name: '',
  components: {
    footerCom,
    navTab
  },
  props: {},
  watch: {},
  data () {
    return {
      activeName: 'first',
      userName: '',
      activeNames: [],
      liveChapterList: [],
      courseExamStatus: false,
      courseTitle: '',
      teachingStatus: 'INIT',
      courseId: '',
      videoList: [],
      studyDateDesc: '',
      processRateTall: '',
      courseTeacher: '',
      courseAddr: '',
      courseType: '',
      courseHeaderTeacher: '',
      courseHeaderLink: '',
      courseIntro: '',
      courseCover_url: '',
      isSignin: 0,
      channelId: '3772942',
      isHasWork: false,
    }
  },
  methods: {
    ...mapMutations({
      setConfigBasicInfo: 'config/setBasicInfo',
      updateNickname: 'config/updateNickname',
      updateUserId: 'config/updateUserId'
    }),
    handleClick (tab, event) {
      console.log(tab, event)
    },
    handleChange (val) {
      console.log(val)
    },
    // 跳转登录
    pageJumpLogin () {
      console.log('111111')
      this.$router.push('/login')
    },
    // 进入录播课
    listenNOTLIVEBtn () {

    },
    // 跳转我的作业页面
    jumpHomeWorkPage () {
      if (!this.isHasWork) {
        this.$message({
          type: 'warning',
          message: '本课程暂无作业！'
        })
      } else {
        if (this.courseId) {
          this.$router.push(`/myHomework?courseId=${this.courseId}`)
        }
      }
    },
    // 跳转到学习轨迹页面
    jumpStudyHistoryPage () {
      this.$router.push(`/studyHistory?courseId=${this.courseId}`)
    },
    // 下载对应课件
    downloadChapterAttach (url) {
      if (url) {
        window.open(url)
      } else {
        this.$message({
          type: 'warning',
          message: '暂无课件可以下载！'
        })
      }
    },
    // 获取课程详情
    getCourseDetail () {
      console.log('mounted', this.$route.params.courseId)
      this.$axios.get(this.$apiUrl.loadCourseDetail, {params: {courseId: this.$route.params.courseId}}).then(res => {
        console.log(res)
        let resData = res.data.data
        let {chapterList, teachingStatus, courseTitle, courseTeacher, courseAddr, courseHeaderTeacher, courseHeaderLink, courseIntro, studyDateDesc, id, courseType, courseCover_url} = resData
        this.courseTitle = courseTitle // 课程标题
        this.studyDateDesc = studyDateDesc // 课程时间
        this.courseTeacher = courseTeacher
        this.courseAddr = courseAddr
        this.courseHeaderTeacher = courseHeaderTeacher
        this.courseHeaderLink = courseHeaderLink
        this.courseIntro = courseIntro
        this.courseId = id
        this.courseType = courseType
        this.courseCover_url = courseCover_url
        console.log(teachingStatus)
        this.teachingStatus = teachingStatus
        this.isHasWork = !!resData.hasWork
        if (resData.examStatus !== 'EXAM_PASS') {
          this.courseExamStatus = true
        } else {
          this.courseExamStatus = false
        }
        // console.log(liveChapterList)
        this.processRateTall = resData.processRate
        // liveChapterList.forEach(item => {
        //   item.liveChapterList.forEach(err => {
        //     err.endTime = err.endTime.slice(11)
        //     this.videoList.push(err.id)
        //     console.log(err)
        //   })
        // })
        // liveChapterList[0].liveChapterList[0].endTime = liveChapterList[0].liveChapterList[0].endTime.slice(11)
        this.liveChapterList = JSON.parse(JSON.stringify(chapterList))
        for (let step = 0; step < this.liveChapterList.length; step++) {
          this.activeNames.push(step)
        }
        this.isSigninFun()
      }).catch(err => {
        console.log(err)
      })
    },
    // 获取是否签到
    isSigninFun () {
      this.$axios.get(this.$apiUrl.getCourseSignin, {params: {id: this.courseId}}).then(res => {
        console.log(res)
        let resData = res.data.data
        let {isSignin} = resData
        this.isSignin = isSignin
      }).catch(err => {
        console.log(err)
      })
    },
    // 进行签到
    setSignin () {
      if (this.isSignin == 'N' && this.courseId) {
        this.$axios.post(this.$apiUrl.saveCourseSignin, {
          id: this.courseId
        }).then(response => {
          let res = response.data
          console.log(res)
          if (res.success) {
            if (res.data) {
              this.isSignin = res.data.isSignin
              this.$message({
                type: 'success',
                message: '报到成功'
              })
            } else {
              this.$router.push(`/userCenterPage?courseId=${this.courseId}`)
            }
          }
        })
      }
    },
    // 获取local中用户信息
    getUserInfo () {
      let userinfo = localStorage.getItem('studentInfo') ? JSON.parse(localStorage.getItem('studentInfo')) : ''
      console.log(userinfo)
      if (userinfo) {
        let {stuName} = userinfo
        this.userName = stuName
      }
      let userIdInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : ''
      if (userIdInfo) {
        let {id} = userIdInfo
        this.updateUserId(id)
      }
    },
    // 回放
    goPlayBackPage (playId) {
      this.$router.push(`/playback?courseId=${this.$route.params.courseId}&studentChapterId=${playId}&pageFrom=pxbcInfo`)// 传过去的视频ID
      console.log(this.$route.params.courseId, 'this.courseId,this.courseIdthis.courseId', playId)
    },
    isOFFLine (playInfo, courseInfo) {
      console.log(playInfo, courseInfo)
      if (courseInfo.courseType == 'OFFLINE' && playInfo.isNeedSignin == 'N' && playInfo.isNeedSignout == 'N') {
        return require('../../assets/image/courseInfo/disabledPlay.png')
      } else {
        return require('../../assets/image/courseInfo/play.png')
      }
    },
    // 直播
    goLivePage (playInfo) {
      console.log(playInfo)
      let {id, vodId, studentCourseId} = playInfo
      if (vodId) {
        // 更新直播时的用户名
        this.updateNickname(this.userName)
        this.$router.push(`/live/${id}?id=${id}&studentCourseId=${studentCourseId}`)
      } else {
        this.$message({
          type: 'error',
          // message: '此课程暂未开播！',
          message: '该章节暂未上传视频！'
        })
      }
    },
    // 线下课程页面
    goOffLinePage (playInfo) {
      let {id} = playInfo
      this.$router.push(`/offLine/${id}`)
    }
  },
  mounted () {
    this.getUserInfo()
    this.getCourseDetail()
  }
}
</script>

<style scoped lang="scss">
.courseInfoWrap{
    position: relative;
    min-height: 100vh;
    header {
        background: url("../../assets/image/homeImg/headerBg.png");
        background-size: 100% 100%;
        height: 344px;
    }
    .hedaer-Bg{
        /* background: url('../../assets/image/homeImg/headerBg.png'); */
        background-size:100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        height: 344px;
        width: 1440px;
        margin: 0 auto;
        padding: 0 100px;
        .header-log-Name{
            height: 42px;
            line-height: 42px;
            .header-left-content{
                font-size: 20px;
                color: white;
                font-weight: 800;
                display: flex;
                justify-content: start;
                .logo-wrap{
                    padding: 0 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        height: 33px;
                        width: 33px;
                    }
                }
                .header-left-name{
                    cursor: pointer;
                }
            }
        }
        .header-right-content{
            font-size: 14px;
            color: white;
            font-weight: 600;
            display: flex;
            justify-content: end;
            position: relative;
            .userName-wrap{
                height: 42px;
                align-items: center;
                display: flex;
                justify-content: center;
            }
            ::v-deep .el-dropdown{
                font-size: 14px;
                color: #4774DF;
                background-color: #fff;
                height: 30px;
                line-height: 30px;
                border-radius: 6px 6px 6px 6px;
                opacity: 1;
                padding: 0px 20px;
            }
        }
        .header-courseTitle{
           padding: 30px 96px 40px 96px;
           .title-content{
                font-size: 28px;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 42px;
                margin-bottom: 30px
           }
           .courseNum-content{
                font-size: 18px;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 42px;
           }
        }
    }
    header{
        position: relative;
        /* margin-bottom: 70px; */
    }
}
.course-content{
    /* width: 90%; */
    padding: 0 100px;
    padding-bottom:500px;
    width: 1440px;
    margin: 0 auto;
    margin-top: -58px;
    .handle-content{
        width: 1120px;
        margin: 0 auto 60px;
        height: 116px;
        background: #FFFFFF;
        box-shadow: 0px 3px 15px 1px rgba(0,0,0,0.12);
        border-radius: 10px 10px 10px 10px;
        .handle-content-col{
            display: flex;
            justify-content: center
        }
        .handle-content-item{
            width: 100px;
            height: 112px;
            font-size: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            align-content: center;
            cursor: pointer;
            img{
                width: 36px;
                height: 36px;
                margin-bottom: 14px;
            }
            >div{
                cursor: pointer;
            }
        }
        .handle-content-item:hover{
            transform: scale(1.01)
        }
    }
    .course-content-cover{
        margin-bottom: 55px;
        display: flex;
    }
    .course-content-cover-left{
        width: 384px;
        height: 255px;
        background: url('../../assets/tuanBg.png') no-repeat;
        background-size:100% 100%;
        border-radius: 5px;
        font-size: 27px;
        color: #FFFFFF;
        line-height: 205px;
        text-align: center;
        z-index: 99;
        display: flex;
        justify-content: center;
        align-items: center;
        .course-content-title{
            line-height: 30px;
            border-radius: 10px;
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            padding: 0px 30px;
            justify-content: center;
            align-content: center;
            color: #ffff;
        }
        .hasCover_url{
            padding: 0px;
            height: 255px;
            img{
              width: 100%;
              height: 255px;
              border-radius: 10px;
            }
        }
    }
    .course-content-cover-right{
        flex: 1;
        font-size: 22px;
        margin-left: 25px;
        height: 205px;
        font-weight: 400;
        color: #000000;
        padding: 10px 0px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        display: flex;
        flex-direction: column;
        position: relative;
        /* align-items: center; */
        justify-content: center;
        >div{
            padding: 4px 0px;
        }
        >div.listenCourse-btn-wrap{
            padding: 0px;
            position: absolute;
            bottom: 20px;
            .listenCourse-btn{
                width: 279px;
                height: 51px;
                background: #FF6F00;
                border-radius: 5px 5px 5px 5px;
                opacity: 1;
                border: 1px solid #FF6F00;
                font-size: 23px;
                font-weight: bold;
                color: #fff
            }
        }
        .notLiveJoinBox{
            display: flex;
            padding-top: 90px;
            flex-direction: column;
            .progess{
                display: flex;
                justify-content: center;
                align-items: center;
                ::v-deep .el-progress{
                    width: 300px;
                    line-height: normal;
                }
            }
            .listenClassBtn{
                width: 300px;
                height: 50px;
                background: #FF6F00;
                font-size: 21px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                text-align: center;
                line-height: 50px;
                margin-left: 50px;
                cursor: pointer;
            }
        }

    }
    .course-content-info{
        background: #FFFFFF;
        box-shadow: 0px 3px 15px 1px rgba(0,0,0,0.12);
        border-radius: 4px 4px 4px 4px;
        padding: 0px 50px 30px;
        ::v-deep .el-tabs__nav-scroll{
            height: 70px;
            line-height: 70px;
            padding: 0 15px;
        }
        ::v-deep .el-tabs__item{
            width: 150px;
            font-size: 20px;
            font-weight: 400;
            text-align: center
        }
        ::v-deep .el-collapse{
            border: 0px solid #fff
        }
        ::v-deep .el-collapse-item__header{
            padding: 30px 41px 30px 39px;
        }
        ::v-deep .el-divider--horizontal{
            margin: 0px
        }
        .course-content-introduce{
            padding: 0px 50px 0px 50px;
            .introduce-item{
                padding-left:20px;
                font-size: 18px;
                font-weight: 400;
                margin-bottom: 20px;
                border-left:5px solid #fff;
            }
            .course-img{
                width: 100%;
                ::v-deep img{
                    width: 100%;
                }
            }
            .introduce-title{
                font-size: 20px;
                font-weight: bold;
                border-left:5px solid #1C4ECC;
            }
        }
        .course-intro-left{
            padding: 22px 0px;
            .course-intro-left-date{
                font-size: 16px;
                color: #1C4ECC;
                margin-bottom: 15px
            }
            .course-intro-left-name{
                font-size: 18px;
                color: #000000;
                 margin-bottom: 17px
            }
            .course-intro-left-teacher{
                font-size: 16px;
                color: #525455;
                display: flex;
                .studyProgess{
                    margin-left:81px;
                     .progessNum{
                        color: #FF6767;
                        margin: 0 10px;
                     }
                }
            }
        }
        .course-intro-center{
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #525455;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 30px;
            >div{
                padding: 10px
            }
        }
        .course-intro{
            padding: 0px 30px 0px 35px;
            background: #DBE8FF;
            .handle-wrap{
                display: flex;
                flex-direction: row;
                /* flex-wrap: nowrap; */
                justify-content:center;
                align-items: center;
            }
            .play-wrap{
                display: flex;
                flex-direction: column;
                cursor: pointer;
                img{
                    width: 24px;
                    height: 24px;
                }
                .noplay{
                   font-size: 14px;
                    color: #8d95a4;
                }
                .play{
                   font-size: 14px;
                    color: #FF6F00;
                }
                .playback{
                   font-size: 14px;
                    color: #4774DF;
                }
            }
            .download-wrap{
                margin-left: 42px;
                width: 60px;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                align-items: center;
                img{
                    width: 24px;
                    height: 24px;
                }
                .noDownloadstr{
                    font-size: 14px;
                    color: #b2b0b0;
                }
            }
        }
    }
}
::v-deep .el-collapse-item__header{
    font-size: 18px;
    color: #000000;
}
.el-collapse-item{
    ::v-deep .el-collapse-item__content{
        padding-bottom: 0px;
    }
}
/depp/ .el-tabs__active-bar{
    width: 80px;
    height: 10px;
    transform: translateX(22px);
}
::v-deep .el-dropdown-menu__item{
    font-size: 32px;
    line-height: 40px;
}
::v-deep .el-dropdown-menu__item {
    font-size: 16px;
    line-height: 20px;
}
</style>
